html, body {
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100%;
}


.forecastInfo {
  position: absolute;
  top: 0;
  left: 0;
  font-family: sans-serif;
  z-index: 1;
  margin: 6px;

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.day {
  display: inline-block;
  margin: 0px 5px;
  opacity: .9;
}

.dateLine {
  margin-left: 5px;
  margin-bottom: 4px;
}
.dateLine > span {
  font-weight: bold;
  background: #fff;
  padding: 0 3px;
  border-radius: 2px;
}

span.temp.min { color: cadetblue; }
span.temp.max { color: darkorange; }

.unit {
  font-size: x-small;
  color: #999;
  vertical-align: bottom;
}
.unit.below {
  vertical-align: top;
}
.unit.after {
  position: relative;
  bottom: .1em;
}


.hours {
  display: table;
  border-spacing: 5px 0;
}
.hour {
  display: table-cell;
  vertical-align: top;
  /* margin-right: 5px; */
  border: .5px solid grey;
  background: #fff;
  border-radius: 4px;
  padding: 4px;
  min-width: 65px;
}

.hour .pair {
  display: flex;
  color: #555;
  margin: 4px 0px;
}
.hour .pair .left {
  width: 30px;
}

.hour .tilde {
  font-size: small;
  position: relative;
  bottom: .1em;
}

.wind > .arrow {
  display: inline-block;
  text-align: center;
  transform-origin: 50% 30%;
}
.wind .arrow .material-symbols-outlined {
  font-size: 21px;
  font-weight: 800;
}
.wind > .text {
  position: relative;
  top: 2px;
}

.wind.low .arrow { color: limegreen; }
.wind.medium .arrow { color: orange; }
.wind.high .arrow { color: red; }

/* .wind .arrow.S   { } */
.wind .arrow.SSW { transform: rotate( 22.5deg); }
.wind .arrow.SW  { transform: rotate( 45.0deg); }
.wind .arrow.WSW { transform: rotate( 67.5deg); }
.wind .arrow.W   { transform: rotate( 90.0deg); }
.wind .arrow.WNW { transform: rotate(112.5deg); }
.wind .arrow.NW  { transform: rotate(135.0deg); }
.wind .arrow.NNW { transform: rotate(157.5deg); }
.wind .arrow.N   { transform: rotate(180.0deg); }
.wind .arrow.NNE { transform: rotate(202.5deg); }
.wind .arrow.NE  { transform: rotate(225.0deg); }
.wind .arrow.ENE { transform: rotate(247.5deg); }
.wind .arrow.E   { transform: rotate(270.0deg); }
.wind .arrow.ESE { transform: rotate(292.5deg); }
.wind .arrow.SE  { transform: rotate(315.0deg); }
.wind .arrow.SSE { transform: rotate(337.5deg); }

.hour .description {
  text-align: center;
  font-size: x-small;
  max-width: 80px;
  margin: 0 auto;
  overflow-x: hidden;
}



.metaBox {
  position: absolute;
  bottom: 2em;
  left: 0;
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  margin: 8px;
  border-radius: 4px;
  opacity: .6;
}

.btn-link {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
}
.btn-link:hover {
  cursor: pointer;
}
.btn-link.active {
  text-decoration: underline;
}